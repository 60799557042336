import axios from 'axios';

function getContacts(){
    return axios({
        method: 'get',
        url: '/api/public/contact.php'})
        .then(res => { return Promise.resolve(res);})
        .catch(error => { return Promise.reject(error);});
}



export default{
    getContacts
};