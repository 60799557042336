import axios from 'axios';

function sendEmail(data){
  
   return axios({
    method:'post',
    data:data,
    url:'https://boomentertraining.com/sendMail.php'
    })
        .then(res => { return Promise.resolve(res);})
        .catch(error => { return Promise.reject(error);});

}


export default{
    sendEmail
};