<template>
<div class="header">
    <div class="d-none d-md-block">
    

     <nav class="navbar d-flex navbar-expand-md text-center  bg-red px-5 py-4 ">
        
         <b-navbar-brand href="#">
            <router-link   to="/">
                <img class="header-logo" src="@/assets/logo-blanco.svg" />
            </router-link>
        </b-navbar-brand>

          <b-navbar-nav class="m-auto ">
                <b-nav-item-dropdown class="header-link mx-5 mobile-menu" text="TRAIN LIKE A HERO!" style="border:none;">
                    <b-dropdown-item  href="#">
                        <router-link   to="/workshops">
                            <div class="drop-down-text"> 
                                Workshops!
                            </div>
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item  href="#">
                        <router-link   to="/made-to-size">
                            <div class="drop-down-text"> 
                                Made to Size!
                            </div>
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item  href="#">
                            <router-link   to="/boom-tools">
                            <div class="drop-down-text"> 
                                BOOM Tools!
                            </div>
                        </router-link>

                    </b-dropdown-item>
                </b-nav-item-dropdown>
            <b-nav-item class="header-link mx-5" href="#">
                 <router-link   to="/activate-powers">
                    <div class="mx-0 header-link"> 
                        ACTIVATE YOUR POWERS!
                    </div>
                </router-link>
            </b-nav-item>
            <b-nav-item class="mx-5" href="#">
                 <router-link    to="/about-boom">
                    <div class="mx-0 header-link"> 
                        ABOUT BOOM
                    </div>
                </router-link>
            </b-nav-item>
        </b-navbar-nav>
              <b-navbar-nav class="mr-4">
                <div class="row bg-white py-3 px-3 rs-container "> 
                    <div class="col-3 px-3 justify-content-center"><a :href="Contacts.LinkiedIn" target="_blank"><img class="p-0 rs-icon" width="30"  src="@/assets/linkedin-rs.svg" /></a></div>
                    <div class="col-3 px-3 justify-content-center"><a :href="Contacts.Youtube" target="_blank"><img class="p-0 rs-icon" width="30"  src="@/assets/youtube-rs.svg" /> </a> </div>
                    <div class="col-3 px-3 justify-content-center"><a :href="Contacts.Whatsapp" target="_blank"><img class="p-0 rs-icon" width="30"  src="@/assets/whatsapp-rs.svg" /></a> </div> 
                    <div class="col-3 px-3 justify-content-center"><a :href="Contacts.Mobile"><img class="p-0 rs-icon" width="30"  src="@/assets/telefono.svg" /></a> </div>      
                </div>
            </b-navbar-nav>
    </nav>
     
    </div>




    <div class="d-md-none">
            
            <nav toggleable="lg"  class="navbar bg-red py-4">
            

                <b-navbar-brand href="#">
                     <router-link   to="/">
                    <img class="sm-header-logo" src="@/assets/logo-blanco.svg" />
                     </router-link>
                </b-navbar-brand>
            

    
            
            <b-navbar-toggle class="ml-2" target="nav-collapse"><img src="@/assets/menu-button.png" width="25" /></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>

                
                <b-nav-item-dropdown class="mobile-menu-item" target="submenu-collapse" text="TRAIN LIKE A HERO!">
                    <b-nav-item class="submenu" id="submenu-collapse" is-nav>
                        <b-nav-item class="sub-menu-item" href="#">
                             <router-link   to="/workshops">
                                <div class=""> 
                                    Workshops
                                </div>
                            </router-link>
                        </b-nav-item>
                        <b-nav-item class="sub-menu-item" href="#">
                              <router-link   to="/made-to-size">
                                    <div class=""> 
                                    Made to Size
                                    </div>
                                </router-link>
                        </b-nav-item>
                        <b-nav-item class="sub-menu-item" href="#">
                            
                                 <router-link   to="/boom-tools">
                                    <div class=""> 
                                    Boom Tools
                                    </div>
                                </router-link>
                            
                             </b-nav-item>
                    </b-nav-item>
                </b-nav-item-dropdown>



                <b-nav-item class="mobile-menu-item" href="#">
                     <router-link   to="/activate-powers">
                        <div class="px-2"> 
                            ACTIVATE YOUR POWERS!
                        </div>
                    </router-link>
                </b-nav-item>
                <b-nav-item class="mobile-menu-item" href="#">
                         <router-link   to="/about-boom">
                            <div class="px-2"> 
                                ABOUT BOOM
                            </div>
                        </router-link>
                </b-nav-item>
            </b-navbar-nav>
                   <div class="container-fluid">
                            <div class="col-1 pt-5 pb-3 px-1 d-flex justify-content-center"><a :href="Contacts.LinkiedIn" target="_blank"><img class="p-0 rs-icon" width="40"  src="@/assets/linkedin-rs.svg" /></a> </div>
                            <div class="col-1 pt-5 pb-3 px-1 d-flex justify-content-center"><a :href="Contacts.Youtube" target="_blank"><img class="m-auto p-0 rs-icon" width="40"  src="@/assets/youtube-rs.svg" /> </a> </div>
                            <div class="col-1 pt-5 pb-3 px-1 d-flex justify-content-center"><a :href="Contacts.Whatsapp" target="_blank"><img class="m-auto p-0 rs-icon" width="40"  src="@/assets/whatsapp-rs.svg" /></a> </div>      
                            <div class="col-1 pt-5 pb-3 px-1 d-flex justify-content-center"><a :href="Contacts.Mobile" ><img class="m-auto p-0 rs-icon" width="40"  src="@/assets/telefono.svg" /></a> </div>
                
            </div>
            </b-collapse>
            
         </nav> 

    
    </div>
</div>


</template>

<script>
// S E R V I C E S


 

import ContactService from "@/service/contact"

  export default {
     components: {
       
  },
    data() {
      return {
        jobs: [],
        formSuccess:false,
        isLoged:false,
        showModal:false,
        changesSuccess:false,
        editImage:false,
        Contacts:{
            Whatsapp: '',
            LinkiedIn: '',
            Youtube: '',
            Mobile: ''
        },
        editFields:{
          text:"",
          image:"",
          field:""
        },
        overlayStyle:{
            background:"#FFFFFF",
            opacity:0.9
        },
      }
    },
    


    mounted(){
      this.getContacts();
    },

     watch:{
      changesSuccess: function(){
        if(this.changesSuccess){
          window.setTimeout(()=>{
            this.changesSuccess = false;
            this.showModal = false;
          }, 3000);
        }
      }
    },
    methods:{
      getContacts(){
        ContactService.getContacts().then((res)=>{
            this.Contacts.Whatsapp =res.data.Whatsapp;
            this.Contacts.LinkiedIn =res.data.LinkiedIn;
            this.Contacts.Youtube =res.data.Youtube;
            this.Contacts.Mobile =res.data.Mobile;
        })
    },

    }
  }
</script>