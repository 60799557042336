<template>
    <div class="footer img-responsive">
     
        <b-row class=" mx-auto mt-25 mb-0">
        <b-col cols="4 mt-5 mt-md-0">
            <div class="d-flex rocket justify-content-end mb-5 mb-md-0">
                    <img class="animation" src="@/assets/left-rocket.svg" />
            </div>
        </b-col>
        <b-col cols="4" >
             <b-row class="d-none d-md-block">
                 <div class="mx-auto mt-25 footer-button d-flex justify-content-center">
                        <button v-on:click.prevent="showModal=true" class="contact-us-button">Join our Newsletter <b-icon-triangle-fill rotate=90 class="button-arrow mb-1"> </b-icon-triangle-fill></button>
                </div>
            </b-row>

             <b-row class="d-block d-md-none">
                 <div class="mx-auto mt-25 footer-button d-flex justify-content-center">
                        <button v-on:click.prevent="showModal=true" class="contact-us-button-mobile">Join our Newsletter <b-icon-triangle-fill rotate=90 class="button-arrow mb-1"> </b-icon-triangle-fill></button>
                </div>
            </b-row>
       
        </b-col>
        <b-col cols="4 mt-5 mt-md-0" >
            <div class=" rocket d-flex">
                    <img class="animation" src="@/assets/right-rocket.svg" />
            </div>
        </b-col>
        </b-row>
     <!-- footer menu -->

          <b-row class="mt-n5">
                 <div class="footer-menu mx-auto d-flex justify-content-center">
                        <span>Follow Us</span>
                </div>
            </b-row>
            <b-row class="justify-content-center">
                <div class="mx-auto mt-2 d-flex ">
                   <a :href="Contacts.LinkiedIn" target="_blank">  <b-col><img class="ml-1 footer-icon"  src="@/assets/linkedin-rs.svg" /> </b-col> </a>
                   <a :href="Contacts.Youtube" target="_blank"> <b-col><img class="mx-1 footer-icon"  src="@/assets/youtube-rs.svg" /> </b-col> </a>
                    <a :href="Contacts.Whatsapp" target="_blank"><b-col><img class="mr-1 footer-icon"  src="@/assets/whatsapp-rs.svg" /> </b-col>  </a>
                    <a :href="Contacts.Mobile"><b-col><img class="mx-1 footer-icon" src="@/assets/telefono.svg" /></b-col></a>    
                </div>
            </b-row>
            <b-row>
                <div class="mx-auto col-12  mb-4 footer-menu d-flex justify-content-center">
                    <router-link class="mx-2" to="/workshops">
                        <div > 
                            Train like a hero
                        </div>
                    </router-link>/
                     <router-link class="mx-2"   to="/activate-powers">
                        <div class=""> 
                            Activate your powers
                        </div>
                    </router-link> /
                    <router-link class="mx-2"   to="/workshops">
                        <div class=""> 
                            Workshops
                        </div>
                    </router-link> / 
                    <router-link class="mx-2" to="/made-to-size">
                        <div class=""> 
                            Made to size
                        </div>
                    </router-link> / 
                    <router-link class="mx-2" to="/boom-tools">
                        <div class=""> 
                            Boom Tools
                        </div>
                    </router-link>/ 
                    <router-link  class="mx-2"  to="/about-boom">
                        <div class=""> 
                            About Boom
                        </div>
                    </router-link> / 
                    <a class="mx-2" target="_blank" href="/Aviso_de_Privacidad_de_BOOM_Entertraining.pdf">
                        <div class=""> 
                            Privacy Policy
                        </div>
                    </a>
                </div>
            </b-row>
    <!-- fin menu -->
    <vue-final-modal v-model="showModal" :overlay-style="overlayStyle">
           <div v-if="formSuccess" class="row">
            <div class="col-10 col-md-4 mx-auto ">
                <div  class=" contact-us-button text-center mt-50">
                    Format sent!
                </div>
            </div>
    </div>
    <div class="row mt-1" v-if="!formSuccess"> 
        <div class="col-md-8 col-10">
        </div>
        <div class="col-md-1 col-1">
            <div v-on:click="showModal=false" class="clickable"> 
                <img src="@/assets/tache.svg" class="remove-x">
            </div>
        </div>
    </div>
    <div class="row" v-if="!formSuccess">
        <div class=" col-10  m-auto modal__content" >
            <span class="red-title red-text"> Join our Newsletter</span><br>
            <div class="row mt-1">
                <b-form class="col-12 col-lg-7 m-auto" >
               
                

                <div class="form-group">
                    <div class="row blue-form">
                <div for="name" class="col-4 form-label">*Name:</div>
                    <div class="col-8">
                    <input v-model="form.name" type="text" class="form-control" name="name" id="name">
                    </div>
                    </div>
                </div>


                <div class="form-group">
                    <div class="row blue-form">
                <div for="email" class="col-4 form-label">*Email:</div>
                    <div class="col-8">
                    <input v-model="form.email" type="email" class="form-control" name="email" id="email">
                    </div>
                    </div>
                </div>

                         <div class="form-group">
                    <div class="row blue-form">
                    <label for="comments" class="col-lg-4">Comments:</label>
                        <div class="col-lg-7">
                            <textarea v-model="form.comments"  
                            rows="2" 
                            class="form-control blue-form comments-text" 
                            name="comments" 
                            id="comments"
                            style="background-color: #b2cee4;  color: #000000;
                                    font-weight: bold; 
                                    font-size:1.6rem;
                                    border-bottom: 1px solid #BB0921;"
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div class="row">
                 <div class="col-6 form-check d-flex justify-content-center">
                        <div class="align-self-center">
                        <input type="checkbox" v-model="form.privacyPolicy" value=true class="red-checkbox mx-2" id="exampleCheck1">
                        <label class="outer-label mx-1" for="exampleCheck1" style="color:#002A49;">
                             <a style="color: #002A49 !important;" target="_blank" href="/Aviso_de_Privacidad_de_BOOM_Entertraining.pdf"> 
                            I have read and accept the Privacy Policy
                           </a>
                        </label>
                     </div>
                </div>

                 <div class="col-6 form-check px-0 d-flex justify-content-end">
                    <button  v-on:click.prevent="send()" :disabled="isFormfilled" :class="disabledClass" class="contact-us-button" for="exampleCheck1">SUBMIT <b-icon-triangle-fill rotate=90 class="button-arrow mb-1"> </b-icon-triangle-fill></button>
                </div>

                </div>
                
                 </b-form>
            </div>
            
        </div>
    </div>    
</vue-final-modal>








    </div>





    
</template>


<script>
import {  VueFinalModal } from 'vue-final-modal'
import EmailService from "@/service/email"
import ContactService from "@/service/contact"

export default {
    components: {
       VueFinalModal
  },

  

  data() {
      return {
        showForm:false,
        formSuccess:false,
        form:{
            name:"",
            email:"",
            comments:"",
            privacyPolicy:false,
            subject:"Newsletter"
        },
        showModal:false,
         overlayStyle:{
            background:"#FFFFFF",
            opacity:0.9
        },
         Contacts:{
            Whatsapp: '',
            LinkiedIn: '',
            Youtube: '',
            Mobile: ''
        },
        
    }
  },


    watch:{
      formSuccess: function(){
        if(this.formSuccess){
          window.setTimeout(()=>{
            this.formSuccess = false;
            this.showForm = false;
            this.showModal = false;
          }, 3000);
        }
      }
    },

   computed:{

    isFormfilled(){
        if(this.form.name !="" &&
            this.form.email !="" &&
            this.form.privacyPolicy
            ){     
                return false;
            }
            return true;

    },


    disabledClass(){
        if(this.isFormfilled){
            return "disabled-button";
        }
        return "";
    }

    },

     mounted(){
      this.getContacts();
    },


    methods:{
           send(){
        this.form.selectedGoals = this.selectedGoals;
          EmailService.sendEmail(this.form).then(()=>{
            window.scrollTo(0,0);
            this.form={};
            this.formSuccess = true;
          }).catch(()=>{
          })
      },
       getContacts(){
        ContactService.getContacts().then((res)=>{
            this.Contacts.Whatsapp =res.data.Whatsapp;
            this.Contacts.LinkiedIn =res.data.LinkiedIn;
            this.Contacts.Youtube =res.data.Youtube;
            this.Contacts.Mobile =res.data.Mobile;
        })
    },

    }
}
</script>